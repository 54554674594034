import {
  ConfigProvider as AntdConfigProvider
} from 'antd'
import { useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import './App.css'
import regression from 'regression'
import svgPanZoom from 'svg-pan-zoom'
import Hammer from 'hammerjs'

import {
  ChartPlotFigure,
  ChartSpace,
  ChartFrame,
  ChartUsa
} from './ChartElements'
import {
  useWindowSize,
  useChartConfig,
  chartConfigContext,
  useProjectQuery,
} from './utils'
import { QueryClient, QueryClientProvider } from 'react-query'
 
const queryClient = new QueryClient()

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Example />
    </QueryClientProvider>
  )
}

function Example() {
  const size = useWindowSize()
  const {config, dispatch} = useChartConfig()
  const [viewportController, setViewportController] = useState(undefined)
  const facetHeight = size.height / 2

  const {
    isLoading,
    error,
    data
  } = useProjectQuery()

  useEffect(() => {
    if (data) {
      const chartData = Object.values(data).filter(d => d.vax_rate > 0)
      dispatch({
        type: 'data',
        payload: {
          chartData: chartData,
          lookupData: data,
          result: regression.linear(chartData.map(d => [d.rawmov, d.vax_rate]))
        }
      })
    }
  }, [data, dispatch])

  useEffect(() => {
    const eventsHandler = {
      haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
      init: function(options) {
        var instance = options.instance
          , initialScale = 1
          , pannedX = 0
          , pannedY = 0
  
        this.hammer = Hammer(options.svgElement, {
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
        })

        // Handle pan
        this.hammer.on('panstart panmove', function(ev){
          // On pan start reset panned variables
          if (ev.type === 'panstart') {
            pannedX = 0
            pannedY = 0
          }

          // Pan only the difference
          instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
          pannedX = ev.deltaX
          pannedY = ev.deltaY
        })
  
        // Enable pinch
        this.hammer.get('pinch').set({enable: true})
  
        // Handle pinch
        this.hammer.on('pinchstart pinchmove', function(ev){
          // On pinch start remember initial zoom
          if (ev.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
          }
  
          instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
        })
  
        // Prevent moving the page on some devices when panning over SVG
        options.svgElement.addEventListener('touchmove', function(e){ e.preventDefault(); });
      },
      destroy: function(){
        this.hammer.destroy()
      }
    }
    if (data) {
      setViewportController(svgPanZoom('#chart-map', {
        dblClickZoomEnabled: false,
        preventMouseEventsDefault: false,
        controlIconsEnabled: true,
        viewportSelector: '#map',
        contain: true,
        fit: true,
        center: true,
        customEventsHandler: eventsHandler
      }))
    }
  }, [data])

  if (isLoading) return 'Loading...'
 
  if (error) return 'An error has occurred: ' + error.message

  return (
    <AntdConfigProvider>
      <chartConfigContext.Provider value={{config, dispatch}}>
        { 
        <ChartFrame
          size={size}
          result={config.result}
          dispatch={dispatch}
          tooltip={config.tooltip}>
          <ChartSpace
            dispatch={dispatch}
            height={facetHeight}
            width={size.width}>
            <rect
              height={facetHeight} width={size.width}
              fill='transparent'
              onMouseMove={() => {
                dispatch({
                  type: "hover",
                  payload: {
                    status: false
                  }
                })
              }}></rect>
            <ChartPlotFigure
              selected={config.selected}
              data={config.lookupData}
              fullData={config.chartData}
              size={size}
              dispatch={dispatch}/>
            <g transform={`translate(${size.width - 160},29)`}>
            <text
              style={{fontWeight:400}}
              fill="currentColor"
              textAnchor="start">
              {config.result.string}
            </text>
            <text
              style={{fontWeight:600}}
              fill="currentColor"
              dy="-1.2em"
              dx="53px"
              textAanchor="start">
              {'R²: ' + config.result.r2}
            </text>
            <path transform="translate(120,-12)" d="m 0 0 h 20" stroke="#282828" strokeDasharray="5,2" strokeWidth="1.5"></path></g>
          </ChartSpace>
          <ChartSpace
            dispatch={dispatch}
            height={facetHeight}
            width={size.width}
            id="chart-map"
            panzoomable>
            <rect
              height={facetHeight} width={size.width}
              fill='transparent'
              onMouseMove={() => {
                dispatch({
                  type: "hover",
                  payload: {
                    status: false
                  }
                })
              }}></rect>
              {/* <text fill="currentColor" transform="translate(0,30)" dy="-1em" text-anchor="start">US President by County, 2020</text> */}
            {data && <ChartUsa viewportController={viewportController} parentId="chart-map" height={facetHeight} width={size.width} dispatch={dispatch}/>}
            <text fill="currentColor" transform="translate(0,30)" dy="-1em" text-anchor="start">US President by County, 2020</text>
          </ChartSpace>
        </ChartFrame>}
      </chartConfigContext.Provider>
    </AntdConfigProvider>
  )
}

