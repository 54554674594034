import { useQuery } from 'react-query'

export const useProjectQuery = () => {
  const { isLoading, error, data } = useQuery('projectData', () =>
    fetch('https://firebasestorage.googleapis.com/v0/b/userp-f8dae.appspot.com/o/startdata.json?alt=media&token=0c5cbd75-f72b-4122-a2a0-ca422b76d0d6').then(res =>
      res.json()
    )
  )
  return {
    isLoading, error, data
  }
}