import {
  Card,
} from 'antd'
import 'antd/dist/antd.css'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { useState } from 'react'
import ChartFrameDrawer from './ChartFrameDrawer'

export const CHART_COLORS = [
  "#00B5E2",
  "#7743f1",
  "#f87575",
]

const CardDrawerLink = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #1890ff;
  :hover {color: #40a9ff;}
  cursor: pointer;
`

const ChartFrame = ({ children, tooltip, size, result }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const drawerW = Math.min(400, size.width)
  return (
    <ChartFrameWrapper>
      <Card 
        title={<div style={{whiteSpace: 'normal'}}>Explore the relationship between partisanship and vaccination progress</div>}
        extra={<CardDrawerLink style={{maxWidth: '30vw'}} onClick={showDrawer}>How to interpret this data?</CardDrawerLink>}
        size='small'
        style={{ width: 'auto'}}>
        {children}
      </Card>
      {tooltip && <ReactTooltip overridePosition={({left, top}) => {
        return {left, top}
      }}>{tooltip}</ReactTooltip>}
      <ChartAttribution style={{float: 'left'}}>Data: CDC, USERP</ChartAttribution>
      <ChartAttribution style={{float: 'right'}} href="https://twitter.com/noahamac" target="_blank">@noahamac</ChartAttribution>
      <ChartFrameDrawer
        drawerW={drawerW}
        visible={visible}
        onClose={onClose}
        result={result}
      />
    </ChartFrameWrapper>
  )
}

export default ChartFrame

const ChartFrameWrapper = styled.div`
  margin: 1em;
  height: 100vh;
`

const ChartAttribution = styled.a`
  margin-top: 0.2em;
  font-size: 0.8em;
`