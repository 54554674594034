import firebase from 'firebase/app'
// import 'firebase/firestore'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyvW_AirQzUUfM70otEQ-wrmZcF670pUA",
  authDomain: "userp-f8dae.firebaseapp.com",
  projectId: "userp-f8dae",
  storageBucket: "userp-f8dae.appspot.com",
  messagingSenderId: "145294053409",
  appId: "1:145294053409:web:fcc09b0f507b2ae72e5360",
  measurementId: "G-C75GNN7PR5"
};

firebase.initializeApp(firebaseConfig)

// export const db = firebase.firestore()

export default firebase