
import styled from "styled-components"

const ChartSpaceSvg = styled.svg`
`

const ChartSpace = (props) => {
	return (
    <ChartSpaceSvg
      id={props.id}
      width={props.width}
      height={props.height}
      onMouseLeave={()=>props.dispatch({type: 'hover', payload: { status: false }})}
      // viewBox={`0 0 ${props.width} ${props.height}`}
      >
      {props.children}
    </ChartSpaceSvg>
  )
}

export default ChartSpace

