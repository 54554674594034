import React, { useReducer } from "react"
import * as d3 from 'd3'

import styled from 'styled-components'

const defaultConfig = {
  lookupData: {},
  chartData: [],
  selected: '',
  tooltip: '',
  result: {},
  showModel: true
}

const calculateOver = (top, bottom) => {
  const math = (top / bottom * 100)
  if (math < 10) {
    return math.toString().substring(0,1)
  }
  return math.toString().substring(0,2)
}

const TooltipText = styled.p`
  margin: 0em;
  padding: 0em;
  font-size: 0.95em;
`

const jsxForTooltipRow = (label, stat) => {
  return (
    <div display='block' key={label}>
      <span><TooltipText style={{float: 'left', paddingRight: '1em'}}><strong>{label}</strong></TooltipText></span>
      <span><TooltipText style={{float: 'right', paddingLeft: '1em'}}>{stat}</TooltipText></span>
    </div>
  )
}

function reducer(state, action) {
  if (action.type === "hover") {
    if (!action.payload.status) {
      return {
        ...state,
        tooltip: undefined,
        selected: '',
      }
    }
    const fips = action.payload.id.substring(1)
    const {
      county_name,
      population,
      fully_vaccinated,
      total,
      mov,
      rawmov
    } = state.lookupData[fips]
    let pred = state.result.predict(rawmov)[1] * 100
    return {
      ...state,
      tooltip: (
        <div>
          <div display='block'><TooltipText style={{textAlign: 'center' }}>{county_name} County</TooltipText></div>
          {jsxForTooltipRow('Population ', ` ${d3.format(",")(population)} `)}
          {jsxForTooltipRow('Results ', ` ${mov} `)}
          {jsxForTooltipRow('Voters ', ` ${d3.format(",")(total)} (${calculateOver(total, population)}%) `)}
          {jsxForTooltipRow('Vaccinated ', ` ${d3.format(",")(fully_vaccinated)} (${calculateOver(fully_vaccinated, population)}%) `)}
          {jsxForTooltipRow('Predicted Vacc. ', ` ${d3.format(",")(pred)}% `)}
        </div>
      ),
      selected: action.payload.id
    }
  } else if (action.type === 'data') {
    return {
      ...state,
      ...action.payload
    }
  } else if (action.type === 'plotInteraction') {
    return {
      ...state,
      showModel: action.payload
    }
  } else {
    throw new Error()
  }
}

const chartConfigContext = React.createContext()

function useChartConfig() {
  const [config, dispatch] = useReducer(
    reducer,
    defaultConfig
  )

  return { config, dispatch }
}

export { useChartConfig, chartConfigContext }