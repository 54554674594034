import { useContext } from 'react'
import * as d3 from 'd3'
import { chartConfigContext } from "../utils"
import { CHART_COLORS } from './ChartFrame'

const ChartGeo = ({id, d, children}) => {
  const { config, dispatch } = useContext(chartConfigContext)
  let hidden = false
  const colorScale = d3.scaleDivergingSymlog()
  .domain([-1, 0, 1])
  .range(CHART_COLORS)
  const key = id.substring(1)
  const obj = config.lookupData[key]
  const mov = obj ? obj["rawmov"] : 0
  if (obj && obj['vax_rate'] === 0) {
    hidden = true
  }
  if (!obj) {
    hidden = true
  }
  const getFill = (id) => {
    if (hidden) {
      return '#cecece'
    }
    return colorScale(mov)
  }
  const getStroke = (id) => {
    if (hidden) {
      return '#cecece'
    }
    return config.selected === id ? 'yellow' : colorScale(mov)
  }
  const selectGeo = () => {
    if (hidden) {
      dispatch({
        type: "hover",
        payload: {
          status: false
        }
      })
    } else {
      dispatch({
        type: "hover",
        payload: {
          id: String(id),
          status: true
        }
      })
    }
  }

	return (
    <path
      id={id}
      key={id}
      data-tip={hidden ? "" : "tooltip"}
      d={d}
      tabIndex={-1}
      onClick={selectGeo}
      onMouseOver={selectGeo}
      style={{
        fill: getFill(id),
        stroke: getStroke(id),
        strokeWidth: config.selected === id ? '2px' : '0.5px'
      }}>
        {/* {children} */}
    </path>
  )
}

export default ChartGeo

