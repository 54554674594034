import React, { useEffect, useRef, memo } from "react"
import * as Plot_ from '@observablehq/plot'
import * as d3 from 'd3'
import { CHART_COLORS } from './ChartFrame'

const reg = require("d3-regression")

function addRegression(Plot) {
  Plot.regression = function ({ x, y, type, bandwidth, order, ...options }) {
    type = String(type).toLowerCase();
    const regressor =
      type === "quad"
        ? reg.regressionQuad()
        : type === "poly"
        ? reg.regressionPoly()
        : type === "pow"
        ? reg.regressionPow()
        : type === "exp"
        ? reg.regressionExp()
        : type === "log"
        ? reg.regressionLog()
        : type === "loess"
        ? reg.regressionLoess()
        : reg.regressionLinear();
    if (bandwidth && regressor.bandwidth) regressor.bandwidth(bandwidth);
    if (order && regressor.order) regressor.order(order);

    const z = options.z || options.stroke; // maybeZ
    return Plot_.transform(options, function (data, facets) {
      const X = Plot_.valueof(data, x);
      const Y = Plot_.valueof(data, y);
      const Z = Plot_.valueof(data, z);
      regressor.x((i) => X[i]).y((i) => Y[i]);

      const regFacets = [];
      const points = [];
      for (const facet of facets) {
        const regFacet = [];
        for (const I of Z ? d3.group(facet, (i) => Z[i]).values() : [facet]) {
          const reg = regressor(I);
          for (const d of reg) {
            const j = points.push(d) - 1;
            if (z) d[z] = Z[I[0]];
            regFacet.push(j);
          }
        }
        regFacets.push(regFacet);
      }
      return { data: points, facets: regFacets };
    });
  };
  return Plot;
}
const Plot = addRegression(Plot_)

const getXLabel = (d) => {
  if (d === 0) {
    return 'Even'
  }
  const candidate = d < 0 ? 'D' : 'R'
  const mov = Math.abs(d) * 100
  return `${candidate}+${mov}`
}

const ChartPlotFigure = ({ selected, data, fullData, size, dispatch }) => {
  selected = selected && selected.substring(1)
  const selectData = selected ? [data[selected]] : []
  const selectX = selected ? [data[selected]['rawmov']] : []
  const selectY = selected ? [data[selected]['vax_rate']] : []
  const options = {}
  options.grid = true
  options.color = {
    range: CHART_COLORS,
    type: 'diverging'
  }
  options.marks = [
    Plot.ruleX(selectX, {strokeDasharray:"4"}),
    Plot.ruleY(selectY, {strokeDasharray:"4"}),
    Plot.dot(fullData, {
      x: "rawmov",
      y: "vax_rate",
      r: 3,
      fill: "rawmov",
      title: d => `${d.county_name} County\nFIPS: ${d.fips}`
    }),
    Plot.line(
      fullData,
      Plot.regression({
        x: "rawmov",
        y: "vax_rate",
        strokeDasharray: [10, 4],
        strokeWidth: 1.5
      })
    ),
    Plot.dot(selectData, {x: "rawmov", y: "vax_rate", stroke: 'yellow'}),
  ]
  options.y = {
    type: 'sqrt',
    label: "↑ Fully Vaccinated Population (%)",
    tickFormat: d => d * 100,
  }
  options.x = {
    type: 'symlog',
    label: null,
    domain: [-.9,.9],
    tickFormat: d => getXLabel(d),
  }
  options.marginTop = 30
  options.width = size.width
  options.height = size.height / 2
  options.style = {
    background: 'white'
  }
  const ref = useRef(null)

  // TODO: Calculate this programmatically
  const W_START = 40
  const W_END = size.width - 20

  const H_START = 25
  const H_END = size.height / 2 - 20

  const x = d3.scaleDivergingSymlog()
  .domain([-.9,.9])
  .range([W_START, W_END])

  const y = d3.scaleSqrt()
  .domain([0,1])
  .range([H_END, H_START])

  const cleanup = () => {
    d3.select(`svg.plot`).remove()
    d3.select(`g.voronoiWrapper`).remove()
  }

  const applyD3 = (svg) => {
    // d3.select(`svg.plot`).remove()
    const voronoi = d3.Delaunay
    .from(fullData, d => x(d.rawmov), d => y(d.vax_rate))
    .voronoi([W_START, H_START, W_END, H_END]); // ensures voronoi is limited to the chart area

    svg.append("g")
    .attr("class", "voronoiWrapper")
    .selectAll("path")
    .data(fullData)
    .join("path")
    .attr("opacity", 0.5)
    .attr("data-tip", "tooltip")
    .attr("fill", "none")
    // .attr("stroke-width", "1px")
    // .attr("stroke", "black")
    .style("pointer-events", "all")
    .attr("d", (d,i) => voronoi.renderCell(i))
    .on("mouseover", (d) => {
      dispatch({
        type: "hover",
        payload: {
          id: 'c' + d.target.__data__.fips,
          status: true
        }
      })
    })
    .on("mouseout", () => {})
  }

  useEffect(() => {
    const plot = Plot.plot(options)
    if (ref.current) {
      if (ref.current.children[0]) {
        ref.current.children[0].remove()
      }
      cleanup()
      ref.current.appendChild(plot)
      applyD3(d3.select(ref.current))
    }
  })
  return <g data-tip="tooltip" ref={ref}
  onMouseEnter={()=>dispatch({type:'plotInteraction', payload: false})}
  onMouseLeave={()=>dispatch({type:'plotInteraction', payload: true})} />
}

export default memo(ChartPlotFigure)